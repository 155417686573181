import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import profile from '@iso/redux/profile/reducer';
import EstateOrDistrict from '@iso/redux/estateOrDistrict/reducer';
import Promocode from '@iso/redux/promocode/reducer';
import SubAdmin from '@iso/redux/subAdmin/reducer';
import RateTable from '@iso/redux/rateTable/reducer';
import Perks from '@iso/redux/perks/reducer';
import MetroStation from '@iso/redux/metroStation/reducer';
import NormalUser from '@iso/redux/normalUser/reducer';
import Realtor from '@iso/redux/realtor/reducer';
import Billing from '@iso/redux/billing/reducer';
import FAQ from '@iso/redux/faq/reducer';
import WantedAds from '@iso/redux/wantedAds/reducer';
import OwnerListing from '@iso/redux/ownerListing/reducer';
import ResidentialListing from '@iso/redux/residentialListing/reducer';
import CommercialListing from '@iso/redux/commercialListing/reducer';
import NewLaunch from '@iso/redux/newLaunch/reducer';
import MediaInfo from '@iso/redux/mediaInfo/reducer';
import AdSpace from '@iso/redux/adSpace/reducer';
import AdSpaceMaster from '@iso/redux/adSpaceMaster/reducer';
import AdPointTransactions from '@iso/redux/adPointTransactions/reducer';
import ResidentialListingMaster from '@iso/redux/residentialListingMaster/reducer';
import CommercialListingMaster from '@iso/redux/commercialListingMaster/reducer';
import GoogleAds from '@iso/redux/googleAds/reducer';
import VerifyPropertyMaster from '@iso/redux/verifyPropertyMaster/reducer';
import StaticPage from '@iso/redux/staticPage/reducer';
import Agencies from '@iso/redux/agencies/reducer';

export default combineReducers({
  Auth,
  App,
  EstateOrDistrict,
  SubAdmin,
  RateTable,
  ThemeSwitcher,
  LanguageSwitcher,
  DynamicChartComponent,
  profile,
  Promocode,
  Perks,
  MetroStation,
  NormalUser,
  Realtor,
  Billing,
  FAQ,
  WantedAds,
  OwnerListing,
  ResidentialListing,
  CommercialListing,
  NewLaunch,
  MediaInfo,
  AdSpace,
  AdSpaceMaster,
  AdPointTransactions,
  ResidentialListingMaster,
  CommercialListingMaster,
  GoogleAds,
  VerifyPropertyMaster,
  StaticPage,
  Agencies
});
