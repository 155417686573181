import {
  initialValues,
  ListInformationInitialValues,
  listingMediaInformationInitialValues
} from '../../schemasAndValidations/PropertyListing';
import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  residentialListing: [],
  modalActive: false,
  residentialList: initialValues,
  residentialPropertyInfo: initialValues,
  residentailListingInfo: ListInformationInitialValues,
  residentailMediaInfo: listingMediaInformationInitialValues
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        residentialListing: payload.data,
        pagination: payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        residentialList: payload.data || initState.residentialList
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data
      };
    case actions.UPDATE_RESIDENTIAL_LIST:
      return {
        ...state,
        residentialList: payload
      };
    case actions.UPDATE_PROPERTY_INFO:
      return {
        ...state,
        residentialPropertyInfo: payload
      };
    case actions.UPDATE_LISTING_INFO:
      return {
        ...state,
        residentailListingInfo: payload
      };
    case actions.UPDATE_MEDIA_INFO:
      return {
        ...state,
        residentailMediaInfo: payload
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
}
