import moment from 'moment';
import { object, string, date, ref } from 'yup';
import { promocodeStatus } from '@iso/config/constant.js';

const validationSchema = object({
  promo_code: string()
    .required('Promo code is required')
    .trim()
    .min(6, 'Promo code must be at least 6 characters long'),
  plan_type: string().trim().required('Type is required'),
  plan_id: string().required('Please Select Plan'),
  discount_ad_points: string()
    .required('Discount ad points is required')
    .matches('^[0-9]+$', 'Discount Ad Points must be number')
    .test('is-valid-range', 'Discount Ad Points must be between 1 and 5000', function (value) {
      const numericValue = parseInt(value);
      return numericValue >= 1 && numericValue <= 5000;
    }),
  start_date: date()
    .required('Valid from date is required')
    .test('start_date', 'Valid From Date must be in Future', function (value) {
      const todayDate = new Date(moment().format('DD/MM/YYYY').split('/').reverse().join('-'));
      const createdDate = new Date(
        moment(this.parent.created_at).format('DD/MM/YYYY').split('/').reverse().join('-')
      );
      const currentDate = new Date(
        moment(value).format('DD/MM/YYYY').split('/').reverse().join('-')
      );
      return !this.parent.created_at ? todayDate <= currentDate : createdDate <= currentDate;
    }),
  expiry_date: date()
    .required('Expiry date is required')
    .min(ref('start_date'), 'Expiry date must be after valid from date')
    .test('expiry_date', 'Expiry date must be after valid from date', function (value) {
      const currentDate = moment(value).startOf('day');
      const startDate = moment(this.parent.start_date).startOf('day');
      const todayDate = moment().startOf('day');
      return currentDate.isSameOrAfter(startDate) && currentDate.isSameOrAfter(todayDate);
    }),
  status: string().trim().required()
});

export const initialValues = {
  promo_code: '',
  plan_type: '1',
  plan_id: null,
  discount_ad_points: '',
  start_date: new Date(),
  expiry_date: new Date(),
  status: promocodeStatus['Active']
};
export default validationSchema;
