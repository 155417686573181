import { object, string } from 'yup';
import { generalStatus, metroLine } from '@iso/config/constant.js';

const validationSchema = object({
  name: string().required('Station name is required').trim(),
  code: string().required('Station code is required').trim(),
  line: string().trim().required(),
  status: string().required()
});

export const initialValues = {
  name: '',
  code: '',
  line: metroLine['East West Line'],
  status: generalStatus['Inactive']
};
export default validationSchema;
