import { isValidPhoneNumber } from 'react-phone-number-input';
import { object, string, array } from 'yup';
import { generalStatus } from '@iso/config/constant.js';

const validationSchema = object({
  name: string()
    .trim()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters')
    .matches('^[a-zA-Z0-9 ]*$', 'Please include alphanumeric only')
    .required('Name is required'),
  email: string()
    .email('Enter valid email')
    .max(64, 'Email must be at most 64 characters')
    .required('Email is required'),
  mobile_number: string()
    .required('Phone is required')
    .test('validate-phone', 'Phone is invalid', (value) => isValidPhoneNumber(value)),
  status: string().trim().required('Status is required'),
  modules: array().min(1, 'Select at least one module')
});

export const initialValues = {
  name: '',
  email: '',
  mobile_number: '',
  status: generalStatus['Active'],
  modules: []
};
export default validationSchema;
