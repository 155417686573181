import { object, string } from 'yup';
import { generalStatus, faqPage } from '@iso/config/constant.js';

const validationSchema = object({
  question: string()
    .min(2, 'Question must be at least 2 characters')
    .max(200, 'Question must be at most 200 characters')
    .required('Question is required')
    .trim(),
  answer: string()
    .min(2, 'Answer must be at least 2 characters')
    .max(500, 'Answer must be at most 500 characters')
    .required('Answer is required')
    .trim(),
  page_name: string().trim().required('Page name is required'),
  group: string().test('conditional-group-required', function (value) {
    const { page_name } = this.parent;
    if (page_name !== faqPage['Market place page']) {
      if (!value) {
        return this.createError({ message: 'Group is required' });
      }
    }
    return true;
  }),
  status: string().trim().required('Status is required')
});

export const initialValues = {
  question: '',
  answer: '',
  page_name: '',
  group: '',
  status: generalStatus['Active']
};
export default validationSchema;
