import actions from './actions';
import { initialValues } from '@iso/schemasAndValidations/NewLaunch';
const initState = {
  isLoading: false,
  errorMessage: false,
  newLaunches: [],
  newLaunch: initialValues
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        newLaunches: payload.data,
        pagination: payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        newLaunch: payload.data || initState.newLaunch
      };
    case actions.API_UPDATE:
      return {
        ...state,
        newLaunch: payload.data
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
}
