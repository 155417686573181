import { object, string } from 'yup';

const validationSchema = object({
  field: string().required(),
  value: string()
    .trim()
    .required('Content is not allowed to empty.')
    .test('html-description', 'Content is not allowed to empty', function (value) {
      if (!value.replace(/<[^>]*>?/gm, '')) {
        return false;
      }
      return true;
    })
});

export const initialValues = {
  field: '',
  value: ''
};
export default validationSchema;
