import { object, string } from 'yup';

const validationSchema = object({
  category: string().required('Category is required'),
  property_type: string().required('Property type is required'),
  property_name: string().required('Property name is required'),
  chineese_name: string().optional(),
  district: string().optional(),
  site_area: string().trim().optional(),
  build_up_sizes: string().trim().optional(),
  price_range: string().trim().optional(),
  expected_top: string().trim().optional(),
  unit_mix: string().trim().optional(),
  launched_tag: string().trim().optional(),
  tenure: string().trim().optional(),
  highest_storey: string().optional(),
  total_units: string().optional(),
  total_towers: string().optional(),
  architect: string().trim().optional(),
  developer: string().trim().optional(),
  description: string().trim().optional(),
  status: string().trim().optional()
});

export const initialValues = {
  category: '',
  property_name: '',
  property_type: '',
  chineese_name: '',
  district: '',
  site_area: '',
  build_up_sizes: '',
  price_range: '',
  location: '',
  mrt_info: '',
  expected_top: '',
  unit_mix: '',
  launched_tag: '',
  tenure: '',
  total_units: '',
  highest_storey: '',
  total_towers: '',
  architect: '',
  developer: '',
  description: '',
  facilities: [],
  amenities: [],
  features: [],
  status: ''
};
export default validationSchema;
