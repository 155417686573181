const DOCUMENT = 'BILLING_';

const actions = {
  LOAD_FROM_API: DOCUMENT + 'LOAD_FROM_API',
  LOAD_FROM_API_SUCCESS: DOCUMENT + 'LOAD_FROM_API_SUCCESS',
  LOAD_FROM_API_ERROR: DOCUMENT + 'LOAD_FROM_API_ERROR',

  TOGGLE_LOADING: DOCUMENT + 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadFromApi: (pagination, data) => {
    return { type: actions.LOAD_FROM_API, pagination, data };
  },

  loadFromApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: { pagination, data }
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: { error }
  })
};
export default actions;
