import { object, string } from 'yup';
import { generalStatus } from '@iso/config/constant.js';

const validationSchema = object({
  isState: string().trim().required('Type is required'),
  name: string()
    .trim()
    .min(2, 'Length must be at least 2 characters')
    .max(50, 'Length must be at most 50 characters')
    .test('conditional-name-required', 'Name is required', function (value) {
      const { isState } = this.parent;
      const isDistrictNameRequired = isState === '0';

      if (isDistrictNameRequired && !value) {
        return this.createError({ message: 'District name is required' });
      }

      if (!isDistrictNameRequired && !value) {
        return this.createError({ message: 'Estate name is required' });
      }

      return true;
    }),
  code: string()
    .trim()
    .min(2, 'District code must be at least 2 characters')
    .max(20, 'District code must be at most 20 characters')
    .test('conditional-code-required', 'District code is required', function (value) {
      const { isState } = this.parent;
      const isCodeRequired = isState === '0';

      if (isCodeRequired && !value) {
        return false;
      }

      return true;
    }),
  status: string().trim().required('Status is required')
});

export const initialValues = {
  isState: 1,
  name: '',
  code: '',
  status: generalStatus['Active']
};
export default validationSchema;
