import { object, string } from 'yup';

const validationSchema = object({
  value: string()
    .min(2, 'Value must be at least 2 characters')
    .max(600, 'Value must be at most 600 characters')
    .trim()
});

export const initialValues = {
  value: ''
};
export default validationSchema;
