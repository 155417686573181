import { isValidPhoneNumber } from 'react-phone-number-input';
import { object, string, ref } from 'yup';
import { generalStatus } from '@iso/config/constant.js';

const validationSchema = object({
  mobile_number: string()
    .required('Phone is required')
    .test('validate-phone', 'Phone is invalid', (value) => isValidPhoneNumber(value)),
  cea_number: string()
    .max(20, 'CEA number must be at most 20 characters')
    .required('CEA number is required')
    .trim(),
  name: string()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters')
    .required('Name is required')
    .trim(),
  business_name: string()
    .max(50, 'Business name must be at most 50 characters')
    .required('Business name is required')
    .trim(),
  email: string()
    .email('Enter valid email')
    .max(64, 'Email must be at most 64 characters')
    .required('Email is required'),
  confirm_email: string()
    .email('Enter valid email')
    .oneOf([ref('email'), null], 'Email do not match')
    .required('Confirm email is required'),
  agency_name: string()
    .max(50, 'Agency name must be at most 50 characters')
    .required('Agency name is required')
    .trim(),
  cea_license: string()
    .max(50, 'CEA license number must be at most 50 characters')
    .required('CEA license number is required')
    .trim(),
  status: string().trim()
  // is_anchor: boolean()
});

export const initialValues = {
  mobile_number: '',
  cea_number: '',
  name: '',
  business_name: '',
  email: '',
  confirm_email: '',
  agency_name: '',
  cea_license: '',
  status: generalStatus['Active']
  // is_anchor: 0
};
export default validationSchema;
