import {
  initialValues,
  ListInformationInitialValues,
  listingMediaInformationInitialValues
} from '../../schemasAndValidations/PropertyListing';
import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  commercialListing: [],
  modalActive: false,
  commercialList: initialValues,
  commercialPropertyInfo: initialValues,
  commercialListingInfo: ListInformationInitialValues,
  commercialMediaInfo: listingMediaInformationInitialValues
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        commercialListing: payload.data,
        pagination: payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        commercialList: payload.data || initState.commercialList
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data
      };
    case actions.UPDATE_COMMERCIAL_LIST:
      return {
        ...state,
        commercialList: payload
      };
    case actions.UPDATE_PROPERTY_INFO:
      return {
        ...state,
        commercialPropertyInfo: payload
      };
    case actions.UPDATE_LISTING_INFO:
      return {
        ...state,
        commercialListingInfo: payload
      };

    case actions.UPDATE_MEDIA_INFO:
      return {
        ...state,
        commercialMediaInfo: payload
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
}
