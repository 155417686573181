import { object, string } from 'yup';
import { generalStatus } from '@iso/config/constant.js';

const validationSchema = object({
  name: string()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters')
    .trim()
    .required('Name is required'),
  type: string().trim().required('Type is required'),
  status: string().trim().required('Status is required')
});

export const initialValues = {
  name: '',
  type: '',
  status: generalStatus['Active']
};
export default validationSchema;
