import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import profileSaga from '@iso/redux/profile/saga';
import estateOrDistrict from '@iso/redux/estateOrDistrict/sagas';
import promocode from '@iso/redux/promocode/sagas';
import subAdmin from '@iso/redux/subAdmin/sagas';
import rateTable from '@iso/redux/rateTable/sagas';
import perks from '@iso/redux/perks/sagas';
import metroStation from '@iso/redux/metroStation/sagas';
import normalUser from '@iso/redux/normalUser/sagas';
import realtor from '@iso/redux/realtor/sagas';
import billing from '@iso/redux/billing/sagas';
import faq from '@iso/redux/faq/sagas';
import wantedAds from '@iso/redux/wantedAds/sagas';
import ownerListing from '@iso/redux/ownerListing/sagas';
import residentialListing from '@iso/redux/residentialListing/sagas';
import commercialListing from '@iso/redux/commercialListing/sagas';
import newLaunch from '@iso/redux/newLaunch/sagas';
import mediaInfo from '@iso/redux/mediaInfo/sagas';
import residentialListingMaster from '@iso/redux/residentialListingMaster/sagas';
import commercialListingMaster from '@iso/redux/commercialListingMaster/sagas';
import adSpace from '@iso/redux/adSpace/sagas';
import adSpaceMaster from '@iso/redux/adSpaceMaster/sagas';
import adPointTransactions from '@iso/redux/adPointTransactions/sagas';
import googleAds from '@iso/redux/googleAds/sagas';
import verifyPropertyMaster from '@iso/redux/verifyPropertyMaster/sagas';
import staticPage from '@iso/redux/staticPage/sagas';
import agencies from '@iso/redux/agencies/sagas';

/* eslint-disable no-unused-vars */
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    profileSaga(),
    estateOrDistrict(),
    subAdmin(),
    promocode(),
    rateTable(),
    perks(),
    metroStation(),
    normalUser(),
    realtor(),
    billing(),
    faq(),
    wantedAds(),
    ownerListing(),
    residentialListing(),
    commercialListing(),
    newLaunch(),
    mediaInfo(),
    residentialListingMaster(),
    commercialListingMaster(),
    adSpace(),
    adSpaceMaster(),
    adPointTransactions(),
    googleAds(),
    verifyPropertyMaster(),
    staticPage(),
    agencies()
  ]);
}
