import { object, string } from 'yup';

const validationSchema = object({
  status: string().trim().required('Status is required')
});

export const initialValues = {
  name: '',
  display_name: '',
  email: '',
  mobile_number: '',
  status: ''
};
export default validationSchema;
