import actions from './actions';
import { initialValues } from '@iso/schemasAndValidations/EstateOrDistrict';
const initState = {
  isLoading: false,
  errorMessage: false,
  estateOrDistrictS: [],
  modalActive: false,
  estateOrDistrict: initialValues,
  isState: 1
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        estateOrDistrictS: payload.data,
        pagination: payload.pagination,
        errorMessage: false
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        errorMessage: 'There is a loading problem'
      };
    case actions.TOGGLE_API_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        estateOrDistrict: payload.data == null ? initState.estateOrDistrict : payload.data
      };
    case actions.API_UPDATE:
      return {
        ...state,
        domina: payload.data
      };
    case actions.TABLE_HANDLER:
      return {
        ...state,
        isState: payload.data
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
}
