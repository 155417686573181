const DOCUMENT = 'RESIDENTIALLISTING_';

const actions = {
  LOAD_FROM_API: DOCUMENT + 'LOAD_FROM_API',
  LOAD_FROM_API_SUCCESS: DOCUMENT + 'LOAD_FROM_API_SUCCESS',
  LOAD_FROM_API_ERROR: DOCUMENT + 'LOAD_FROM_API_ERROR',

  SAVE_INTO_API: DOCUMENT + 'SAVE_INTO_API',
  SAVE_INTO_API_ERROR: DOCUMENT + 'SAVE_INTO_API_ERROR',

  RESET_API_DOCUMENTS_ERROR: DOCUMENT + 'RESET_API_DOCUMENTS_ERROR',

  TOGGLE_API_HANDLE_MODAL: DOCUMENT + 'TOGGLE_API_HANDLE_MODAL',
  API_UPDATE: DOCUMENT + 'API_UPDATE',
  UPDATE_RESIDENTIAL_LIST: DOCUMENT + 'UPDATE_RESIDENTIAL_LIST',
  UPDATE_PROPERTY_INFO: DOCUMENT + 'UPDATE_PROPERTY_INFO',
  UPDATE_LISTING_INFO: DOCUMENT + 'UPDATE_LISTING_INFO',
  UPDATE_MEDIA_INFO: DOCUMENT + 'UPDATE_MEDIA_INFO',

  TOGGLE_LOADING: DOCUMENT + 'TOGGLE_LOADING',

  toggleLoading: (value) => ({ type: actions.TOGGLE_LOADING, payload: { value } }),

  loadFromApi: (pagination, data) => ({
    type: actions.LOAD_FROM_API,
    pagination,
    data
  }),

  loadFromApiSuccess: (pagination, data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: { pagination, data }
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: { error }
  }),

  saveIntoApi: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_API,
    payload: { data, actionName }
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_API_HANDLE_MODAL,
    payload: { data }
  }),

  update: (data) => ({
    type: actions.API_UPDATE,
    payload: { data }
  }),

  saveIntoAPIError: (error) => ({
    type: actions.SAVE_INTO_API_ERROR,
    payload: { error }
  }),

  updateResidentialList: (data) => ({
    type: actions.UPDATE_RESIDENTIAL_LIST,
    payload: data
  }),

  updateResidentialPropertyInfo: (data) => ({
    type: actions.UPDATE_PROPERTY_INFO,
    payload: data
  }),

  updateResidentialListingInfo: (data) => ({
    type: actions.UPDATE_LISTING_INFO,
    payload: data
  }),

  updateResidentialMediaInfo: (data) => ({
    type: actions.UPDATE_MEDIA_INFO,
    payload: data
  })
};

export default actions;
