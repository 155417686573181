import { string, object, number } from 'yup';

const MediaInformationSchema = object().shape({
  youtube_url: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  youtube_url_1: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  youtube_url_2: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  virtual_tour_url: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  virtual_tour_url_1: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  virtual_tour_url_2: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  is_star_quality: number().required('Star Quality is required')
});

export const initialValues = {
  youtube_url: '',
  youtube_url_1: '',
  youtube_url_2: '',
  virtual_tour_url: '',
  virtual_tour_url_1: '',
  virtual_tour_url_2: '',
  is_star_quality: 0
};
export default MediaInformationSchema;
