import { object, string } from 'yup';
import { plansNumber } from '@iso/config/constant';

const validationSchema = object({
  group_name: string().required('Group Name is required').trim(),
  plan_type: string().required(),
  validity_number: string()
    .matches('^[0-9]+$', 'Validity must be a number')
    .test('conditional-validity-required', function (value) {
      const { plan_type, validity_unit } = this.parent;
      const isValidityRequired =
        plan_type === plansNumber['Subscription'] ||
        plan_type === plansNumber['Supercharge'] ||
        plan_type === plansNumber['Property Listing'];

      const maxValidityValues = {
        days: 90,
        months: 48,
        years: 25
      };

      if (isValidityRequired) {
        if (!value) {
          return this.createError({ message: 'Validity is required' });
        }
        if (parseInt(value) <= 0 || !/^\d+$/.test(value)) {
          return this.createError({ message: 'Validity must be a positive number' });
        }
        if (
          validity_unit in maxValidityValues &&
          parseInt(value) > maxValidityValues[validity_unit]
        ) {
          return this.createError({
            message: `Maximum validity for ${validity_unit} is ${maxValidityValues[validity_unit]}`
          });
        }
      }

      return true;
    }),
  validity_unit: string().trim().optional(),
  ad_points: string()
    .matches('^[0-9]+$', 'Ad points must be number')
    .test('conditional-ad-points-required', function (value) {
      const { plan_type } = this.parent;
      const isAdPointsRequired =
        plan_type === plansNumber['Subscription'] ||
        plan_type === plansNumber['Top Up'] ||
        plan_type === plansNumber['Referrer'] ||
        plan_type === plansNumber['Referee'] ||
        plan_type === plansNumber['Ad Points'];
      if (isAdPointsRequired) {
        if (!value) {
          return this.createError({ message: 'Ad points is required' });
        }
        if (parseInt(value) < 1 || parseInt(value) > 99999) {
          return this.createError({ message: 'Ad points must be between 1 and 99999' });
        }
      }
      return true;
    }),
  concurrent_listing: string()
    .matches('^[0-9]+$', 'Concurrent Listing must be number')
    .test('conditional-concurrent-listing-required', function (value) {
      const { plan_type } = this.parent;
      if (plan_type === plansNumber['Subscription']) {
        if (!value) {
          return this.createError({ message: 'Concurrent Listing is required' });
        }
        if (parseInt(value) < 1 || parseInt(value) > 1000) {
          return this.createError({ message: 'Concurrent Listing must be between 1 and 1000' });
        }
      }
      return true;
    }),
  refreshment: string()
    .matches('^[0-9]+$', 'Number of refresh must be number')
    .test('conditional-refreshment-required', function (value) {
      const { plan_type } = this.parent;
      if (plan_type === plansNumber['Supercharge']) {
        if (!value) {
          return this.createError({ message: 'Number of Auto Refresh is required' });
        }
        if (parseInt(value) < 1 || parseInt(value) > 25) {
          return this.createError({ message: 'Number of Auto Refresh must be between 1 and 25' });
        }
      }
      return true;
    }),
  normal_rate: string()
    .matches('^[0-9]+$', 'Normal Rate must be number')
    .test('conditional-normal-rate-required', function (value) {
      const { plan_type } = this.parent;
      const isNormalRateNotRequired =
        plan_type === plansNumber['Referrer'] ||
        plan_type === plansNumber['Referee'] ||
        plan_type === plansNumber['Ad Points'];
      if (!isNormalRateNotRequired) {
        if (!value) {
          return this.createError({ message: 'Normal Rate is required' });
        }
        if (parseInt(value) > 999999) {
          return this.createError({ message: 'Normal Rate must be less than 999999' });
        }
      }
      return true;
    }),
  offer_rate: string()
    .matches('^[0-9]+$', 'Offer Rate must be number')
    .test(
      'offer_rate',
      'Offer Rate cannot be greater than Normal Rate',
      function (offerRate, context) {
        const normalRate = context.parent.normal_rate;
        if (offerRate && normalRate) {
          return parseInt(offerRate) <= parseInt(normalRate);
        }
        return true;
      }
    ),
  pre_offer_rate: string(),
  units: string(),
  status: string().required()
});

export const initialValues = {
  group_name: '',
  plan_type: '',
  refreshment: '',
  validity_number: '',
  validity_unit: '',
  ad_points: '',
  concurrent_listing: '',
  normal_rate: '',
  offer_rate: '',
  pre_offer_rate: '',
  units: '',
  status: '',
  visibility: 1
};
export default validationSchema;
