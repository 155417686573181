import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

function* loadFromApi(payload) {
  try {
    yield put(actions.loadFromApiSuccess(payload.pagination, payload.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FROM_API, loadFromApi)]);
}
